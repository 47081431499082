body#tinymce {
  margin: 12px !important;
  background: #fff;
  max-width: 710px;
  > h1:first-child {
    margin-top: 0px;
  }
  > h2:first-child {
    margin-top: 0px;
  }
  > h3:first-child {
    margin-top: 0px;
  }
  > h4:first-child {
    margin-top: 0px;
  }
  > h5:first-child {
    margin-top: 0px;
  }
}
